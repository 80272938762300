<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_status') }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit()"
      >{{ $t('message.save') }}</el-button>
      <el-button type="warning" icon="el-icon-close" size="small" @click="close">{{ $t('message.close') }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form-item :label="columns.title.title">
              <el-input v-model="form.title" :placeholder="columns.title.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="columns.state.title">
              <el-input v-model="form.state" :placeholder="columns.state.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";

export default {
  mixins: [drawerChild, form],
  computed: {
    ...mapGetters({
      columns: "statuses/columns",
      model: "statuses/model",
      rules: "statuses/rules",
    }),
  },
  methods: {
    ...mapActions({
      save: "statuses/store",
      update: "statuses/update",
      show: "statuses/show",
      empty: "statuses/empty",
      getInventory: "statuses/inventory",
    }),
    submit(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.form = res.data.status;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
            })
            .catch((err) => {
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
              this.loadingButton = false;
            })
            .finally(() => {
              this.getInventory();
              this.$emit('onStatusCreated', this.form.id);
              this.close();
            });
        }
      });
    },
  },
};
</script>
