<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_aggrement') }}</span>
      <el-button type="success" size="small" class="mr-1" @click="submitForm()">{{ $t('message.save') }}</el-button>
      <el-button type="warning" icon="el-icon-close" size="small" @click="close()">{{ $t('message.close') }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" :rules="rules" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="40">
          <el-col :span="8">
            <el-form-item :label="columns.number.title" prop="number">
              <el-input v-model="form.number" :placeholder="columns.number.title"></el-input>
            </el-form-item>
            <!-- end el-form-item-->

            <el-form-item :label="columns.begin_date.title" prop="begin_date">
              <el-date-picker
                v-model="form.begin_date"
                type="date"
                :placeholder="columns.begin_date.title"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
            <!-- end el-form-item-->
          </el-col>

          <el-col :span="8">
            <el-form-item :label="columns.status_id.title" prop="status_id">
              <el-row :gutter="5">
                <el-col :span="22">
                  <el-form-item class="mb-0">
                    <statuses v-model="form.status_id" :status_id="form.status_id"></statuses>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- end el-form-item-->

            <el-form-item :label="columns.supplier_id.title" prop="supplier_id">
              <el-row :gutter="5">
                <el-col :span="22">
                  <el-form-item class="mb-0">
                    <suppliers v-model="form.supplier_id" :supplier_id="form.supplier_id"></suppliers>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="mb-0">
                  <div class="plusic" @click="drawer.provider.status = true">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- end el-form-item-->
          </el-col>
          <!-- end  col -->

          <el-col :span="8">
            <el-form-item :label="columns.sum.title" prop="sum">
              <el-input v-model="form.sum" :placeholder="columns.sum.title"></el-input>
            </el-form-item>
            <!-- end el-form-item-->

            <el-form-item :label="columns.description.title" prop="description">
              <el-input
                type="textarea"
                v-model="form.description"
                :placeholder="columns.description.title"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end  col -->
        </el-row>
      </el-form>
    </div>

    <el-drawer
      size="70%" :wrapperClosable="false"
      :visible.sync="drawer.provider.status"
      :with-header="false"
      :ref="drawer.provider.name"
      :append-to-body="true"
    >
      <Provider :drawer-name="drawer.provider.name" @onSupplierCreated="setCreatedSupplier" />
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import Status from "./status-modal";
import Provider from "./provider-modal";

import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

import statuses from '@/components/inventory-select/statuses';
import suppliers from '@/components/inventory-select/suppliers';

export default {
  components: { Status, Provider, statuses, suppliers },
  mixins: [drawerChild, form, drawer],
  data() {
    return {
      drawer: {
        provider: {
          name: "provider",
          status: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      model: "supplierContracts/model",
      rules: "supplierContracts/rules",
      columns: "supplierContracts/columns",
      suppliers: "suppliers/inventory",
      statuses: "statuses/inventory",
    }),
  },
  methods: {
    ...mapActions({
      save: "supplierContracts/store",
      update: "supplierContracts/update",
      editModel: "supplierContracts/show",
      empty: "supplierContracts/empty",
      getInventory: 'supplierContracts/inventory'
    }),
    submitForm(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.form = res.data.supplier_contract;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.getInventory();
              this.$emit('onContractCreated', this.form.id);
              this.close();
            })
            .catch((err) => {
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
              this.loadingButton = false;
            })
            .finally(() => {
              if (resetForm) {
                this.$refs["form"].resetFields();
                this.empty();
              }
            });
        }
      });
    },
    setCreatedSupplier(supplierID) {
      this.form.supplier_id = supplierID;
    }
  },
};
</script>
